<dx-data-grid class="gridContainer box-shadow" #gridIncinerate [dataSource]="edit.data" [allowColumnReordering]="true"
	[height]="ui.fullHeight" [remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowEdit)">

	<dxo-export [enabled]="ui.exportEnabled" fileName="incinerate"></dxo-export>
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="localStorage" storageKey="qhseIncinerate">
	</dxo-state-storing>
	<dxo-scrolling mode="infinite" [showScrollbar]="ui.showScrollbar" [useNative]="!ui.isWindows"></dxo-scrolling>

	<dxi-column type="buttons" fixedPosition="left" [minWidth]="proxy.actionsWidth" [width]="proxy.actionsWidth"
		[fixed]="true" [allowResizing]="false" [showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit"></dxi-button>
		<dxi-button icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete"></dxi-button>
	</dxi-column>

	<dxi-column name='incinerate' headerCellTemplate='incinerateHeader'>
		<dxi-column [caption]="L('ELIMINATION_DATE')" dataField="e_date" dataType="date" [width]="120"
			calculateSortValue="e_date" [calculateDisplayValue]="iui.formatDate"></dxi-column>
		<dxi-column [caption]="L('WORK_CENTER')" dataField="wc_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('INCINERATE_LINE')" dataField="e_deviceId" dataType="string"></dxi-column>
	</dxi-column>
	<dxi-column name='waste' headerCellTemplate='wasteHeader'>
		<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('ELIMINATION_CODE')" dataField="e_eliminationId" dataType="string" [visible]="edit.type == '0'">
			<dxo-lookup [dataSource]="proxy.wasteType.eliminationCode" valueExpr="id" displayExpr="code"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('CAPITALIZATION_CODE')" dataField="e_capitalizationId" dataType="string" [visible]="edit.type == '1'">
			<dxo-lookup [dataSource]="proxy.wasteType.capitalizationCode" valueExpr="id" displayExpr="code"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('WASTE_TYPE')" dataField="wt_hazard" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.hazard" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('WASTE_NAME')" dataField="wt_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('PHYSICAL_STATE')" dataField="wt_physicalCondition" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.physicalState" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column name='quantity' headerCellTemplate='qHeader'>
		<dxi-column [caption]="L('QUANTITY')" dataField="e_quantity" dataType="number"></dxi-column>
		<dxi-column [caption]="L('U')" dataField="uom" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"></dxo-lookup>
		</dxi-column>
	</dxi-column>
	<dxi-column [caption]="L('OBSERVATIONS')" dataField="e_observations" dataType="string"></dxi-column>

	<dxo-summary>
		<dxi-total-item column="e_quantity" summaryType="sum" displayFormat="Total: {0}"
			[valueFormat]="{type: 'fixedPoint', precision: 2 }">
		</dxi-total-item>
	</dxo-summary>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'incinerateHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class="fas fa-industry"></i>  {{ L('SOURCE') }}
	</div>
	<div *dxTemplate="let c of 'wasteHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-recycle'></i> {{ L('WASTE') }}
	</div>
	<div *dxTemplate="let c of 'qHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-weight'></i> {{ L('QUANTITY') }}
	</div>
</dx-data-grid>
	<ibis-edit-popup [edit]="edit" [showAdd]="true">
		<app-incinerate-edit></app-incinerate-edit>
	</ibis-edit-popup>