<dx-form #incinerateForm [formData]="edit.model" [showValidationSummary]="false" labelLocation="left"
	[validationGroup]="edit.validation" [colCount]="3">

	<dxi-item itemType="group" [caption]="L('SOURCE')" [colSpan]="3" [colCount]="3">
		<dxi-item [label]="{text: L('ENT_WORKC')}" dataField="workCenterId" editorType="dxSelectBox" [colSpan]="2"
			[editorOptions]="{dataSource: proxy.party.ownWorkCenter, valueExpr: 'c_id', displayExpr: 'fullName', searchEnabled: true, onValueChanged: onWorkCenterChanged }">
		</dxi-item>
		<dxi-item [label]="{text: edit.type == '0' ? L('ELIMINATION_DATE') : L('RECYCLE_DATE')}" dataField="date"
			editorType="dxDateBox" [editorOptions]="{ width: '100%', displayFormat: 'dd.MM.yyyy' }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
	</dxi-item>

	<dxi-item itemType="group" [caption]="L('WASTE')" [colSpan]="3" [colCount]="3">
		<dxi-item [label]="{text: L('WASTE_CODE')}" dataField="wasteType.code" editorType="dxTextBox"
			[visible]="edit.actualStock && edit.type == '0'"
			[editorOptions]="{ onInitialized: onWasteTypeInit, buttons: [{name: 'Search', options: { icon: 'find', onClick: onOpenStockPopup }}], 
                disabled: edit.model.workCenterId == undefined || edit.model.workCenterId == null, onFocusIn: onFocusEv }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('WASTE_CODE') }" dataField="wasteTypeId" editorType="dxSelectBox"
			[visible]="edit.type == '1'" [editorOptions]="{ dataSource: proxy.wasteType.code, valueExpr: 'id', displayExpr: 'code', disabled: !edit.model.workCenterId, 
				onInitialized: onWasteTypeInit, onValueChanged: onWasteTypeChanged, searchEnabled: true }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('QUANTITY') }" dataField="quantity" editorType="dxNumberBox"
			[editorOptions]="{buttons: [{name: 'uom', location: 'after', options: uomHelper.options }], onFocusIn: onFocusEv}">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: edit.type == '0' ? L('INCINERATE_LINE') : L('RECYCLE_LINE')}" dataField="deviceId"
			editorType="dxSelectBox" [editorOptions]="{ dataSource: edit.deviceLine }">
			<dxi-validation-rule type="required"></dxi-validation-rule>
		</dxi-item>
		<dxi-item [label]="{text: L('WASTE_NAME')}" dataField="wasteType.name" editorType="dxTextBox" [colSpan]="3"
			[editorOptions]="{ readOnly: true }">
		</dxi-item>
	</dxi-item>

	<dxi-item [label]="{text: L('OBSERVATIONS')}" dataField="observations" editorType="dxTextArea" [colSpan]='3'
		[editorOptions]="{ }"></dxi-item>
</dx-form>

<ibis-edit-popup [edit]="proxy.actualStock">
	<app-actual-stock-edit></app-actual-stock-edit>
</ibis-edit-popup>