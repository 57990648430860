<dx-data-grid #gridGenerate class="gridContainer" [dataSource]="edit.data" [height]="ui.fullHeight"
	[remoteOperations]="true" (onToolbarPreparing)="toolbarPreparing($event, proxy.allowDelete)" columnResizingMode='widget'>

	<dxo-export fileName="generate"></dxo-export>
	<!-- <dxo-scrolling mode="virtual" rowRenderingMode="virtual" [renderAsync]='false'>
	</dxo-scrolling> -->
	<dxo-state-storing [enabled]="ui.stateStoringEnabled" type="custom" [storageKey]='key'>
	</dxo-state-storing>

	<dxi-column type="buttons" [width]="proxy.actionsWidth" fixedPosition="left" [fixed]="true" [allowResizing]="false"
		[showInColumnChooser]="false" [visible]="proxy.showActions">
		<dxi-button [hint]="L('DELIVERY')" icon="car" [onClick]="onTransportClick" [visible]="false"></dxi-button>
		<dxi-button [hint]="L('TRANSFER')" icon="redo" [onClick]="onTransferClick" [visible]="false"></dxi-button>
		<dxi-button [hint]="L('EDIT')" icon="edit" [onClick]="onEditClick" [visible]="proxy.allowEdit">
		</dxi-button>
		<dxi-button [hint]="L('DELETE')" icon="trash" [onClick]="onDeleteClick" [visible]="proxy.allowDelete">
		</dxi-button>
	</dxi-column>

	<dxi-column name='generator' headerCellTemplate='genHeader'>
		<dxi-column [caption]="L('ENTITY')" dataField="ent_name" dataType="string"></dxi-column>
		<dxi-column [caption]="L('WORK_CENTER')" dataField="wc_name" dataType="string"></dxi-column>
		<dxi-column [caption]="edit.wasteType == '1' ? L('MATERIAL') : L('CATEGORY')" dataField="m_description"
			dataType="string">
		</dxi-column>
		<dxi-column [caption]="L('OPERATION_DATE')" dataField="g_date" dataType="date" calculateSortValue="g_date"
			[calculateDisplayValue]="iui.formatDate">
		</dxi-column>
	</dxi-column>

	<dxi-column name='waste' headerCellTemplate='wasteHeader'>
		<dxi-column [caption]="L('PACKING_TYPE')" dataField="cat_description" dataType="string">
		</dxi-column>
		<dxi-column [caption]="L('WASTE_CODE')" dataField="wt_code" dataType="string"></dxi-column>
		<dxi-column [caption]="L('WASTE_NAME')" dataField="wt_name" dataType="string"></dxi-column>
	</dxi-column>

	<dxi-column name='quantity' headerCellTemplate='qHeader'>
		<dxi-column [caption]=" L('QUANTITY') " dataField="g_quantity" dataType="number"></dxi-column>
		<dxi-column [caption]="L('PIECE')" dataField="g_piece" dataType="number"></dxi-column>
		<dxi-column [caption]="L('U')" dataField="uom" dataType="number">
			<dxo-lookup [dataSource]="proxy.wasteType.uom" valueExpr="id" displayExpr="value"> </dxo-lookup>
		</dxi-column>

		<dxi-column [caption]="L('EVALUATION')" dataField="g_estimated" dataType="boolean">
			<dxo-lookup [dataSource]="edit.evaluation" valueExpr="id" displayExpr="value"> </dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('SOURCE')" dataField="g_source" dataType="number">
			<dxo-lookup [dataSource]="edit.source" valueExpr="id" displayExpr="value"> </dxo-lookup>
		</dxi-column>
		<dxi-column [caption]="L('STORAGE_TYPE')" dataField="wtp_storageTypeId" dataType="string">
			<dxo-lookup [dataSource]="proxy.wasteType.storageType" valueExpr="id"
				[displayExpr]="proxy.wasteType.storageDisplay"> </dxo-lookup>
		</dxi-column>
	</dxi-column>

	<dxi-column [caption]="L('OBSERVATIONS')" dataField="g_observations" dataType="string">
	</dxi-column>
	<dxi-column name='audit' headerCellTemplate='auditHeader'>
		<dxi-column [caption]="L('MADE_BY')" dataField="ti_createdBy" dataType="string"></dxi-column>
		<dxi-column [caption]="L('MODIFIED_BY')" dataField="ti_modifiedBy" dataType="string"></dxi-column>
		<dxi-column [caption]="L('CREATION_DATE')" dataField="ti_created" dataType="datetime"
			calculateSortValue="ti_created" [calculateDisplayValue]="iui.formatDateTime"></dxi-column>
		<dxi-column [caption]="L('LAST_MODIFIED')" dataField="ti_modified" dataType="datetime"
			calculateSortValue="ti_modified" [calculateDisplayValue]="iui.formatDateTime"></dxi-column>
	</dxi-column>

	<div *dxTemplate="let data of 'breadcrumb'">
		<ibis-breadcrumb></ibis-breadcrumb>
	</div>
	<div *dxTemplate="let c of 'genHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-industry'></i> {{L('GENERATOR')}}
	</div>
	<div *dxTemplate="let c of 'wasteHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-recycle'></i> {{L('WASTE')}}
	</div>
	<div *dxTemplate="let c of 'qHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='fas fa-weight'></i> Cantitate
	</div>
	<div *dxTemplate="let c of 'auditHeader'" class="dx-datagrid-text-content" role="presentation">
		<i class='far fa-calendar-alt'></i> Audit
	</div>
</dx-data-grid>