<dx-data-grid #gridActualStockSelect class="gridContainer" height="50vh" 
    [dataSource]="edit.dsStock" [selectedRowKeys]="edit.selectedKeys" keyExpr="id"
    (onRowUpdating)="onStockUpdating($event)" (onSelectionChanged)="onSelectionChange($event)"
    (onEditingStart)="onEditingStart($event)" (onToolbarPreparing)="toolbarPreparing($event, false, true)">

    <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
    <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

    <dxi-column [caption]="L('CREATION_DATE')" dataField="date" dataType="date" [allowEditing]="false">
    </dxi-column>
    <dxi-column [caption]="L('ACTUAL_QUANTITY')" dataField="remain" dataType="number" [allowEditing]="false"
        [allowHeaderFiltering]="false">
    </dxi-column>
    <dxi-column [caption]="L('SELECTED_QUANTITY')" dataField="quantity" dataType="number"
        [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column [caption]="L('GENERATOR')" dataField="company" dataType="string" [allowEditing]="false">
    </dxi-column>
    <dxi-column [caption]="L('WORK_CENTER')" dataField="workCenter" dataType="string" [allowEditing]="false"
        [allowHeaderFiltering]="false">
    </dxi-column>
    <dxi-column [caption]="L('OBSERVATIONS')" dataField="observations" dataType="string" [allowEditing]="false"
        [allowHeaderFiltering]="false">
    </dxi-column>

    <div *dxTemplate="let data of 'wasteCode'">
        <dx-select-box [dataSource]="edit.wasteType" valueExpr="id" displayExpr="code" [searchEnabled]="true"
            [showClearButton]="true" [(value)]="edit.model.wasteTypeId"
            [readOnly]="edit && edit.model && edit.model.edit == true" [readOnly]="edit.model.eliminationId"
            (onValueChanged)="loadStockDataSource($event)">
        </dx-select-box>
    </div>

    <div *dxTemplate="let data of 'totalQuantity'">
        <div class="dx-toolbar-label">Total: {{edit.model.totalQuantity}} {{edit.model.uom || ''}}</div>
    </div>

</dx-data-grid>